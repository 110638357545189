/*
 * @Descripttion: 天道通勤
 * @version: 1.0.1
 * @Author: 汤玉鹏
 * @Date: 2021-12-20 11:39:08
 * @LastEditors: typ
 * @LastEditTime: 2022-10-18 10:29:40
 */
import DemoConfig from "@/router/modules/DemoConfig";
import LoginConfig from "@/router/modules/LoginConfig";
import IndexConfig from "@/router/modules/IndexConfig";
import MineConfig from "@/router/modules/MineConfig";
import CommonConfig from "@/router/modules/CommonConfig";

const PageRouter = [
  DemoConfig.demo,
  CommonConfig.search,
  LoginConfig.login,
  LoginConfig.code,
  LoginConfig.register,
  LoginConfig.forgotPassword,
  LoginConfig.weChatLogin,
  MineConfig.weChatBind,
  MineConfig.account,
  MineConfig.help,
  MineConfig.privacy,
  MineConfig.notice,
  MineConfig.collect,
  MineConfig.address,
  MineConfig.coupon,
  MineConfig.opinion,
  MineConfig.order,
  MineConfig.orderDetail,
  MineConfig.orderComment,
  MineConfig.orderProcess,
  MineConfig.orderLogistics,
  IndexConfig.home,
  IndexConfig.embroideryHome,
  IndexConfig.customEmbroideryDetail,
  IndexConfig.embroideryHomeDetail,
  IndexConfig.warmLife,
  IndexConfig.warmLifeDetail,
  IndexConfig.guide,
  IndexConfig.diy,
  IndexConfig.status,
  IndexConfig.shoppingCart,
  IndexConfig.confirmOrder,
  IndexConfig.pay,
  IndexConfig.diyDetails,
];
//模块暴露
export default PageRouter;
