/*
 * @Descripttion: 天道通勤
 * @version: 1.0.1
 * @Author: 汤玉鹏
 * @Date: 2021-11-01 10:19:00
 * @LastEditors: typ
 * @LastEditTime: 2021-11-01 11:59:58
 */
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { Message } from 'element-ui';
import router from '@/router';
import Qs from 'qs'
import base from '@/utils/base'


// 创建axios的实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 30000, // 超时时间
    withCredentials: false, //表示跨域请求时是否需要使用凭证，默认为false
});

// 请求拦截
service.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
        config.headers['Accept'] = 'application/json, text/plain, */*'
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        const token = localStorage.getItem('_TOKEN')
        if(config.isToken){
            config.headers['Authorization'] = "Bearer " + token
        }
        else{
          if(!base.isNull(token)){
            config.headers['Authorization'] = "Bearer " + token
          }
        }

        if(config.method == 'post'){
          const data = {...config.params}
          config.params = {}
          config.data = Qs.parse(data)
        }
        return config;
    },
    (err: any) => {
        Promise.reject(err);
    }
);

// 响应拦截
service.interceptors.response.use(
    async (response: AxiosResponse) => {
      switch (response.data.code) {
        case 500:
          Message.error('网络异常，请稍后在试！');
          break;
        case 401:
          // Message.error('登录状态失效，请重新登录');
          base.quitLogin()
          break;
        case 404:
          Message.error('网络请求不存在');
          break;
        case 409:
            Message.error(response.data.msg)
            router.push('/');
          break;
        default:
          return response.data;
          break;
      }
    },
    (err: any) => {
        let errMsg = '';
        if (err.response) {
            switch (err.response.status) {
                case 401:
                    // errMsg = '登录状态失效，请重新登录';
                    router.push('/');
                    base.quitLogin()
                    break;
                case 404:
                    errMsg = '网络请求不存在';
                    router.push('/');
                    break;
                default:
                    errMsg = err.response.data.msg;
                    break;
            }
        } else {
            errMsg = err;
        }

        Message.error(errMsg);
        return Promise.reject(errMsg);
    }
);

export default service
