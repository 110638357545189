const DemoConfig = {
  demo: {
    path: '/Demo',
    name: 'Demo',
    component: () => import('@/views/Demo/Index/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:false
    },
  },
};
export default DemoConfig;
