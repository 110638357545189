// import {consoleLog} from "echarts/types/src/util/log";

export const computedMapDict = function () {
    return {
        _dictGender: () => [...gender],
    }
}

export const filtersMapDict = function () {
    return {
        _moneyFormat: (number: number | string, decimals: number | string, dec_point: number | string, thousands_sep: number | string) => {
            decimals = 2;
            number = (number + '').replace(/[^0-9+-Ee.]/g, '');
            let n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec: any = (typeof dec_point === 'undefined') ? '.' : dec_point,
                s: any = '',
                toFixedFix = function (n: number, prec: number) {
                    let k = Math.pow(10, prec);
                    return '' + Math.ceil(n * k) / k;
                };

            s = (prec ? toFixedFix(n, prec) : '' + Math.floor(n)).split('.');
            let re = /(-?\d+)(\d{3})/;
            while (re.test(s[0])) {
                s[0] = s[0].replace(re, "$1" + sep + "$2");
            }

            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }
            return s.join(dec);
        },
        _endTimeFormat: (str: string) => {
            if (str != undefined) {
                let aTimer = str.split(':');
                return aTimer[0] + ":" + aTimer[1]
            }
        },
        _endDateFormat: (str: string) => {
            if (str != undefined) {
                let aTimer = str.split(' ');
                return aTimer[0]
            }
        },
        _endMonthFormat: (str: string) => {
            if (str != undefined) {
                let aTimer = str.split(' ');
                aTimer = aTimer[0].split('-');
                return aTimer[1] + '-' + aTimer[2]
            }
        },
        _endTimerFormat: (str: string) => {
            if (str != undefined) {
                let aTimer = str.split(' ');
                return aTimer[1]
            }
        },
        _dateFormat: (dataStr: string) => {
            if (dataStr) {
                var time = new Date(dataStr);
                function timeAdd0(str: string | number) {
                    if (str < 10) {
                        str = '0' + str;
                    }
                    return str
                }
                var y = time.getFullYear();
                var m = time.getMonth() + 1;
                var d = time.getDate();
                var h = time.getHours();
                var mm = time.getMinutes();
                var s = time.getSeconds();
                return y + '-' + timeAdd0(m) + '-' + timeAdd0(d) + ' ' + timeAdd0(h) + ':' + timeAdd0(mm) + ':' + timeAdd0(s);
            }
        },
        // 将时间戳转换成时间格式
        timestampToTime(timestamp: any) {

        },
        _minuteTostring: (dataStr: any) => {
            console.log('[  ] >', dataStr)
            if (dataStr) {
                if (!isNaN(dataStr)) {
                    console.log('[  ] >', dataStr)

                    let newtimestamp = dataStr ? dataStr.toString() : ''
                    if (newtimestamp.length == 13) {
                        newtimestamp = parseInt(newtimestamp.substring(0, newtimestamp.length - 3));
                    }
                    var date = new Date(newtimestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
                    var Y = date.getFullYear() + '-';
                    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                    var D = date.getDate() + ' ';
                    var h = date.getHours() + ':';
                    var m = date.getMinutes();
                    var s = date.getSeconds();
                    return Y + M + D + h + m;
                    // let dataTime=dataStr.substring(0,16)
                    // console.log(dataTime);
                    // return dataTime;
                } else {
                    let dataTime = dataStr.substring(0, 16)
                    console.log(dataTime);
                    return dataTime;
                }

            }
        },
        _minuteFormat: (dataStr: string) => {
            if (dataStr) {
                var time = new Date(dataStr);
                function timeAdd0(str: string | number) {
                    if (str < 10) {
                        str = '0' + str;
                    }
                    return str
                }
                var y = time.getFullYear();
                var m = time.getMonth() + 1;
                var d = time.getDate();
                var h = time.getHours();
                var mm = time.getMinutes();
                return y + '年' + timeAdd0(m) + '月' + timeAdd0(d) + '日 ' + timeAdd0(h) + ':' + timeAdd0(mm);
            }
        },
        _dayFormat: (dataStr: string) => {
            if (dataStr) {
                var time = new Date(dataStr);
                function timeAdd0(str: string | number) {
                    if (str < 10) {
                        str = '0' + str;
                    }
                    return str
                }
                var y = time.getFullYear();
                var m = time.getMonth() + 1;
                var d = time.getDate();
                return y + '年' + timeAdd0(m) + '月' + timeAdd0(d) + '日';
            }
        },
        _dayTooFormat: (dataStr: string) => {
            if (dataStr) {
                var time = new Date(dataStr);
                function timeAdd0(str: string | number) {
                    if (str < 10) {
                        str = '0' + str;
                    }
                    return str
                }
                var y = time.getFullYear();
                var m = time.getMonth() + 1;
                var d = time.getDate();
                return y + '-' + timeAdd0(m) + '-' + timeAdd0(d);
            }
        },
        _formatDate(dataStr: string) {
            const date: any = new Date(dataStr);
            return date.getTime(date);
        },
        _filterGender: (key: string | number) => {
            if (typeof key == "number") {
                return gender.get(key.toString())
            }
            return gender.get(key)
        },

    }
}

/**
 * 性别
 * @type {Map<any, any>}
 */
export const gender = new Map([
    ['0', '女'],
    ['1', '男'],
])


