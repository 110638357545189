const CommonConfig = {
  search: {
    path: '/Search',
    name: 'Search',
    config:"CommonConfig",
    key:'search',
    component: () => import('@/views/Common/Search/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:false
    },
  },

};
export default CommonConfig;
