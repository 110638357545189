
import {Component, Provide, Vue} from 'vue-property-decorator';
@Component({
  components: {
  },
})
export default class HeaderLogin extends Vue {
  @Provide() showArr:Array<string> = ['/home']
  @Provide() logoShow:boolean = false

  mounted(){
    const _this:any = this;
  }

  /**
   * @Author HS
   * @Date 2021/9/3 5:28 下午
   * @Description: 页面跳转
   * @Params: { string ：link - true } [跳转地址]
   * @Params: { boolean ：openLink - false } [是否打开新窗口]
   * @Return: null
  */
  goRoute(link:string,openLink:boolean){
    const _this:any = this;
    if(openLink){
      window.open(link, "_blank");
      return;
    }
    _this.$base.goRouter(_this.$IndexConfig[link].path)
  }

  /**
   * @Author HS
   * @Date 2021/7/2 3:59 下午
   * @Description: Mine页面跳转
   * @Params: { string ： path - true } [path：路由]
   * @Return:
   */
  goMine(path:string){
    const _this:any = this;
    if(_this.$base.isLogin()){
      const link = _this.$base.routeLinkSplice(_this.$LoginConfig.login.path,path)
      _this.$base.goRouter(link)
      return
    }
    _this.$base.goRouter(_this.$MineConfig[path].path)
  }

}
