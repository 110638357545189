/**
 * @Author: HS
 * @Date: 2021/7/5 2:17 下午
 * @Description: 公共导出数据
 * @LastEditTime: 2021/7/5 2:17 下午
 * @LastEditors:
 * @FilePath: src/utils/const.ts
*/

export const iUrl = process.env.VUE_APP_BASE_URL;
export const uploadUrl = iUrl + "/shop/common/uploadFile";
export const uploadFilesUrl = iUrl + "/shop/common/uploadFiles";
export const headers = {
  Authorization:localStorage.getItem('_TOKEN')
};
