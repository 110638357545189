

import {Component, Vue, Prop, Provide, Watch} from 'vue-property-decorator';
import { SexRadio } from "@/interface/pagas/mine";
@Component({
  components: {
  },
})



export default class Radio extends Vue {
  @Prop({ type: Array, default: '默认' }) readonly data !: Array<SexRadio>;
  @Prop({ type: Number, default: -1 }) readonly value !: number;
  @Provide() val:number = -1
  @Watch('value',{immediate:true, deep:true})
  private iValChange():void {
    const _this:any = this
    _this.val = _this.value
  }
  created(){
  }
  /**
   * @Author HS
   * @Date 2021/7/5 4:11 下午
   * @Description: 选择元素
   * @Params: { number ： index - true } [下标]
   * @Return: null
  */
  callback(index:number){
    const _this:any = this;
    _this.val = _this.data[index].value
    this.$emit('radioCallback',_this.val)
  }
}
