const IndexConfig = {
  home: {
    path: '/',
    name: 'Home',
    config:"IndexConfig",
    key:'home',
    component: () => import('@/views/Index/Home/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:false
    },
  },
  embroideryHome: {
    path: '/EmbroideryHome',
    name: 'EmbroideryHome',
    config:"IndexConfig",
    key:'embroideryHome',
    component: () => import('@/views/Index/EmbroideryHome/List/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:false
    },
  },
  customEmbroideryDetail: {
    path: '/CustomEmbroideryDetail',
    name: 'CustomEmbroideryDetail',
    config:"IndexConfig",
    key:'CustomEmbroideryDetail',
    component: () => import('@/views/Index/EmbroideryHome/CustomDetails/Index.vue'),
    meta: {
      showTop: false,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:false
    },
  },
  embroideryHomeDetail: {
    path: '/EmbroideryHomeDetail',
    name: 'EmbroideryHomeDetail',
    config:"IndexConfig",
    key:'embroideryHomeDetail',
    component: () => import('@/views/Index/EmbroideryHome/Details/Index.vue'),
    meta: {
      showTop: false,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:false
    },
  },
  warmLife: {
    path: '/WarmLife',
    name: 'WarmLife',
    config:"IndexConfig",
    key:'warmLife',
    component: () => import('@/views/Index/WarmLife/List/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:false
    },
  },
  warmLifeDetail: {
    path: '/WarmLifeDetail',
    name: 'WarmLifeDetail',
    config:"IndexConfig",
    key:'warmLifeDetail',
    component: () => import('@/views/Index/WarmLife/Details/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:false
    },
  },
  guide: {
    path: '/Guide',
    name: 'Guide',
    config:"IndexConfig",
    key:'guide',
    component: () => import('@/views/Index/Customization/Guide/Index.vue'),
    meta: {
      showTop: false,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:false,
    },
  },
  diy: {
    path: '/Diy',
    name: 'Diy',
    config:"IndexConfig",
    key:'diy',
    component: () => import('@/views/Index/Customization/Diy/Index.vue'),
    meta: {
      showTop: false,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:true,
      isRouter:"diy"
    },
  },
  diyDetails: {
    path: '/DiyDetails',
    name: 'DiyDetails',
    config:"IndexConfig",
    key:'diyDetails',
    component: () => import('@/views/Index/Customization/Details/Index.vue'),
    meta: {
      showTop: false,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:true,
    },
  },
  status: {
    path: '/Status',
    name: 'Status',
    config:"IndexConfig",
    key:'status',
    component: () => import('@/views/Index/ShoppingCart/Status/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:true,
    },
  },
  shoppingCart: {
    path: '/ShoppingCart',
    name: 'ShoppingCart',
    config:"IndexConfig",
    key:'shoppingCart',
    component: () => import('@/views/Index/ShoppingCart/List/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:true,
      isRouter:"shoppingCart"
    },
  },
  confirmOrder: {
    path: '/ConfirmOrder',
    name: 'ConfirmOrder',
    config:"IndexConfig",
    key:'confirmOrder',
    component: () => import('@/views/Index/Order/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:true
    },
  },
  pay: {
    path: '/Pay',
    name: 'Pay',
    config:"IndexConfig",
    key:'pay',
    component: () => import('@/views/Index/Pay/Index.vue'),
    meta: {
      showTop: false,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:true
    },
  },
};
export default IndexConfig;
