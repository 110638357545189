// import request from "../request"
import request from "../http"
import {
    AccountLoginReq,
    AccountRegisterReq,
    ChangePasswordReq,
    ForgetPasswordReq,
    PhoneLoginReq,
    VerificationCodeReq
} from '@/interface/res/login'
//密码登录
export function AccountLoginApi (params:AccountLoginReq){
    return request({
        url: '/shop/account/auth/accountLogin',
        method: 'post',
        params,
        isToken:false
    })
}
//密码登录
export function PhoneLoginApi (params:PhoneLoginReq){
    return request({
        url: '/shop/account/auth/phoneLogin',
        method: 'post',
        params,
        isToken:false
    })
}
//注册
export function AccountRegisterApi (params:AccountRegisterReq){
    return request({
        url: '/shop/account/auth/accountRegister',
        method: 'post',
        params,
        isToken:false
    })
}
//忘记密码
export function ForgetPasswordApi (params:ForgetPasswordReq){
    return request({
        url: '/shop/account/info/forgetPassword',
        method: 'post',
        params,
        isToken:false
    })
}
//修改密码
export function ChangePasswordApi (params:ChangePasswordReq){
    return request({
        url: '/shop/account/info/changePassword',
        method: 'post',
        params,
        isToken:false
    })
}
//发送忘记密码验证码
export function SmsForgetPasswordApi (params:VerificationCodeReq){
    return request({
        url: '/shop/account/sms/forgetPassword',
        method: 'get',
        params,
        isToken:false
    })
}
//发送注册验证码
export function SmsRegisterApi (params:VerificationCodeReq){
    return request({
        url: '/shop/account/sms/register',
        method: 'get',
        params,
        isToken:false
    })
}
//修改密码验证码
export function SmsChangePasswordApi (params:VerificationCodeReq){
    return request({
        url: '/shop/account/sms/changePassword',
        method: 'get',
        params,
        isToken:true
    })
}
//获取手机登录验证码
export function SmsPhoneLoginApi (params:VerificationCodeReq){
    return request({
        url: '/shop/account/sms/phoneLogin',
        method: 'get',
        params,
        isToken:true
    })
}

//退出登录
export function LoginOutApi (params:{} = {}){
    return request({
        url: '/shop/account/auth/loginOut',
        method: 'post',
        params,
        isToken:true
    })
}

//密码登录
export function OauthBindAccountApi (params:PhoneLoginReq){
    return request({
        url: '/shop/account/oauth/login/oauthBindAccount',
        method: 'post',
        params,
        isToken:false
    })
}



