

import { Component, Vue, Prop, Provide } from 'vue-property-decorator';
@Component({
  components: {
  },
})



export default class Button extends Vue {
  @Prop({ type: String, default: '默认' }) readonly text ?: string;
  @Prop({ type: String, default: '' }) readonly iType ?: string;
  @Prop({ type: String, default: '100%' }) readonly iWidth ?: string;
  @Prop({ type: String, default: '46' }) readonly iHeight ?: string;
  @Prop({ type: String, default: '10' }) readonly iRadius ?: string;
  @Prop({ type: String, default: '20' }) readonly fontSize ?: string;
  @Prop({ type: String, default: '' }) readonly icon ?: string;
  @Prop({ type: Boolean, default: false }) readonly iDisabled ?: boolean;
  @Prop({ type: Boolean, default: false }) readonly iLoading ?: boolean;

  created(){
  }

  callback(){
    this.$emit('btnCallback')
  }
}
