import Vue from 'vue'
import Vuex from 'vuex'
import demo from "@/store/modules/demo"
import user from "@/store/modules/user"
import shoppingCart from "@/store/modules/shoppingCart"
import webSocket from "@/store/modules/webSocket"
import service from "@/store/modules/service"
import address from "@/store/modules/address"
import pay from "@/store/modules/pay"
import getters from "@/store/getter"

Vue.use(Vuex)

export default new Vuex.Store({
  mutations: {
  },
  actions: {
  },
  modules: {
    demo,
    user,
    shoppingCart,
    webSocket,
    service,
    address,
    pay
  },
  getters
})
